<script>
import { emailInputStoreRecentContacts } from '@/utils/helpers/email-input-helpers';

export default {
  name: 'HrbrEditFolderCollaborators',

  props: ['currentfolderid', 'currentfoldername', 'allFoldersArray', 'emailInputData'],

  data() {
    return {
      collaboratorsViewers: [],
      collaboratorsEditors: [],
      collaboratorsViewersPrior: [],
      collaboratorsEditorsPrior: [],
      userIsCollaborator: false,
      userIsViewer: false,
      orgIsCollaborator: false,
      orgIsViewer: false,
      isModalInitialDataLoaded: false,
      folderShareURL: '',
      systemEmail: '',
      storeRecentContacts: emailInputStoreRecentContacts,
    };
  },

  computed: {
    closeButton() {
      return { text: 'Cancel', styleType: 'secondary' }
    },
    updateButton() {
      return {
        text: 'Update',
        styleType: 'primary',
        style: { marginLeft: '6px' },
        disabled: this.userIsCollaborator == false && this.orgIsCollaborator == false,
      };
    },
  },

  created() {
    // Get initial share settings
    let self = this;

    self.$harbourData
      .post('data?filesystem-getfolderaccesssettings', {
        requesttype: 'filesystem-getfolderaccesssettings',
        currentfolderid: self.currentfolderid,
      })
      .then((successResponse) => {
        self.isModalInitialDataLoaded = true;
        self.collaboratorsEditors =
          successResponse.data.folderpermissions_collaboratorsarray.filter(function (tgtValue) {
            return tgtValue.includes('@') && tgtValue.includes('.');
          });
        self.collaboratorsViewers = successResponse.data.folderpermissions_viewersarray.filter(
          function (tgtValue) {
            return tgtValue.includes('@') && tgtValue.includes('.');
          },
        );
        self.collaboratorsEditorsPrior =
          successResponse.data.folderpermissions_collaboratorsarray.filter(function (tgtValue) {
            return tgtValue.includes('@') && tgtValue.includes('.');
          });
        self.collaboratorsViewersPrior = successResponse.data.folderpermissions_viewersarray.filter(
          function (tgtValue) {
            return tgtValue.includes('@') && tgtValue.includes('.');
          },
        );
        self.userIsCollaborator = successResponse.data.user_iscollaborator;
        self.userIsViewer = successResponse.data.user_isviewer;
        self.orgIsCollaborator = successResponse.data.org_iscollaborator;
        self.orgIsViewer = successResponse.data.org_isviewer;
        self.systemEmail = successResponse.data.systememail;
      })
      .catch((errorResponse) => {
        console.log('Folder sharing error', errorResponse);
        alert(
          'Error: unable to load folder access at this time: please try again later or contact support@harbourshare.com',
        );
        self.closeModal();
      });

    const shareUrl = new URL(`${window.location.origin}/folders`);
    shareUrl.searchParams.set('folderid', self.currentfolderid);

    // Update URL
    self.folderShareURL = shareUrl.toString();
  },

  mounted() {
    // Disable browser's text autocomplete
    let self = this;
    Array.from(self.$refs['viewersshare'].$el.querySelectorAll('*')).forEach(function (el) {
      el.setAttribute('autocomplete', 'no-show-autocomplete');
    });
    Array.from(self.$refs['editorsshare'].$el.querySelectorAll('*')).forEach(function (el) {
      el.setAttribute('autocomplete', 'no-show-autocomplete');
    });
  },

  methods: {
    async updateFolderAccessTimeout() {
      // the delay is needed to add contacts on blur event
      await new Promise((resolve) => setTimeout(resolve, 250));
      this.updateFolderAccess();
    },

    // Update folder access
    updateFolderAccess() {
      // Clean input
      let self = this;
      let cleanedCollaboratorsViewers = self.collaboratorsViewers.filter(function (tgtEmail) {
        return tgtEmail.includes('@') && tgtEmail.includes('.') && !tgtEmail.includes(' ');
      });
      self.collaboratorsViewers = cleanedCollaboratorsViewers;
      let cleanedCollaboratorsEditors = self.collaboratorsEditors.filter(function (tgtEmail) {
        return tgtEmail.includes('@') && tgtEmail.includes('.') && !tgtEmail.includes(' ');
      });
      self.collaboratorsEditors = cleanedCollaboratorsEditors;

      // Alert - can't remove creator (except if creator)
      let folder = self.allFoldersArray.find((tgtFolder) => tgtFolder.id === self.currentfolderid);

      if (folder && folder.creatorEmail != undefined) {
        let folderCreatorEmail = folder.creatorEmail;
        if (self.collaboratorsEditorsPrior.includes(folderCreatorEmail) == true) {
          if (self.collaboratorsEditors.includes(folderCreatorEmail) == false) {
            if (self.systemEmail != folderCreatorEmail) {
              alert(
                "I am afraid you can't remove the original folder creator (" +
                  folderCreatorEmail +
                  ') -- please update to proceed',
              );
              return false;
            }
          }
        }
      }

      // Confirm - attempt to remove self editing access
      if (self.collaboratorsViewersPrior.includes(self.systemEmail) == true) {
        if (self.collaboratorsViewers.includes(self.systemEmail) == false) {
          if (confirm('Are you certain you want to remove your viewing access here?') != true) {
            return false;
          }
        }
      }
      // Confirm - attempt to remove self editing access
      if (self.collaboratorsEditorsPrior.includes(self.systemEmail) == true) {
        if (self.collaboratorsEditors.includes(self.systemEmail) == false) {
          if (confirm('Are you certain you want to remove your edit access here?') != true) {
            return false;
          }
        }
      }
      // Alert - no editors?
      if (self.collaboratorsEditors.length == 0 && self.orgIsCollaborator == false) {
        if (confirm('Are you certain you want to remove *all editors* from the folder?') == false) {
          return false;
        }
      }

      // Get diffs
      let editorsAccessDiff = getArrayDiff(
        self.collaboratorsEditorsPrior,
        self.collaboratorsEditors,
      );
      let editorsRemoved = editorsAccessDiff.filter(function (d) {
        return self.collaboratorsEditorsPrior.includes(d);
      });
      //
      let viewersAccessDiff = getArrayDiff(
        self.collaboratorsViewersPrior,
        self.collaboratorsViewers,
      );
      let viewersRemoved = viewersAccessDiff.filter(function (d) {
        return self.collaboratorsViewersPrior.includes(d);
      });

      //
      let newCollaboratorsAddedList = getArrayDiff(
        self.collaboratorsViewersPrior.concat(self.collaboratorsEditorsPrior),
        self.collaboratorsViewers.concat(self.collaboratorsEditors),
      );
      let newCollaboratorsAddedListUnique = Array.from(new Set(newCollaboratorsAddedList));

      // Get children folders
      let childrenFolders = [];

      const getChildrenFolders = (folderId, allFolders) => {
        let childrenIds = [];
        allFolders.forEach((folder) => {
          const { folderPath } = folder;
          if (!folderPath.includes(folderId)) return;
          const index = folderPath.findIndex((path) => path === folderId);
          const fromNext = index + 1;
          const sliced = folderPath.slice(fromNext);
          childrenIds.push(...sliced);
        });
        childrenIds = Array.from(new Set(childrenIds));

        const mapFolder = (id) => allFolders.find((folder) => folder.id === id);
        const childrenFolders = childrenIds.map(mapFolder);
        return childrenFolders;
      };
      childrenFolders = getChildrenFolders(self.currentfolderid, self.allFoldersArray);

      //Update current folder (*complete access update)
      self.$buefy.toast.open({
        duration: 2500,
        message: 'Success - folder sharing updated',
        position: 'is-top',
        type: 'is-success',
      });
      self.closeModal();

      self.$harbourData
        .post('data?filesystem-storefolderaccesssettings', {
          requesttype: 'filesystem-storefolderaccesssettings',
          currentfolderid: self.currentfolderid,
          currentfoldername: self.currentfoldername,
          collaboratorviewers: self.collaboratorsViewers,
          collaboratoreditors: self.collaboratorsEditors,
          orgiscollaborator: self.orgIsCollaborator,
          orgisviewer: self.orgIsViewer,
        })
        .then((successResponse) => {
          setSubFolderDiffAccess();
          verifyAccessAndMessage(newCollaboratorsAddedListUnique);
        })
        .catch((errorResponse) => {
          console.log('Asset sharing error', errorResponse);
          alert(
            'Error: update - while process your request, an error occurred: please try again later or contact support@harbourshare.com',
          );
        });

      // Update subfolder access with access change delta (*add newly added, remove newly removed)
      function setSubFolderDiffAccess() {
        childrenFolders.forEach(function (childFolder) {
          // Append child folder access with new access (*delta/change/diff access - add update)
          self.$harbourData
            .post('data?filesystem-appendfolderaccesssettings', {
              requesttype: 'filesystem-appendfolderaccesssettings',
              targetfolderid: childFolder.id,
              targetfoldername: childFolder.name,
              collaboratorviewers: self.collaboratorsViewers,
              collaboratoreditors: self.collaboratorsEditors,
              orgiscollaborator: self.orgIsCollaborator,
              orgisviewer: self.orgIsViewer,
            })
            .then((successResponse) => {
              // Trim child folder access with new access (*delta/change/diff access - remove update)
              self.$harbourData
                .post('data?filesystem-trimfolderaccesssettings', {
                  requesttype: 'filesystem-trimfolderaccesssettings',
                  targetfolderid: childFolder.id,
                  targetfoldername: childFolder.name,
                  collaboratorsviewerstoremove: viewersRemoved,
                  collaboratorneditorstoremove: editorsRemoved,
                  orgiscollaborator: self.orgIsCollaborator,
                  orgisviewer: self.orgIsViewer,
                })
                .then((successResponse) => {})
                .catch((errorResponse) => {
                  console.log('Subfolder folder sharing removing access error', errorResponse);
                });
            })
            .catch((errorResponse) => {
              console.log('Subfolder folder sharing added access error', errorResponse);
            });
        });
      }

      // Verify access and message
      function verifyAccessAndMessage(newCollaboratorsAddedListUnique) {
        if (newCollaboratorsAddedListUnique.length == 0) {
          console.log(' -- no addeded collaborators for verification and messaging');
          return true;
        }

        self.$harbourData
          .post('data?filesystem-verifysharingandmessage', {
            requesttype: 'filesystem-verifysharingandmessage',
            currentfolderid: self.currentfolderid,
            currentfoldername: self.currentfoldername,
            newcollaboratorslist: newCollaboratorsAddedListUnique,
          })
          .then((successResponse) => {})
          .catch((errorResponse) => {
            console.log('Subfolder folder sharing removing access error', errorResponse);
          });
      }

      // store emails in history
      self.storeRecentContacts(
        [...self.collaboratorsViewers, ...self.collaboratorsEditors],
        self.emailInputData.emailsToNames,
      );
    },

    // Close modal
    closeModal() {
      this.$parent.close();
    },

    // Open mail
    openMailTo(tgtSubjectMsg, tgtBodyMsg, tgtToEmail) {
      let subject = tgtSubjectMsg;
      let body = tgtBodyMsg.split('<BR>').join('\n');
      body = body.replace(/%22/g, '"');
      window.open(
        'mailto:?subject=' +
          encodeURIComponent(subject) +
          '&body=' +
          encodeURIComponent(body) +
          '&to=' +
          encodeURIComponent(tgtToEmail),
      );
    },
  },
};

// Get array diff (as array)
function getArrayDiff(a1, a2) {
  let a = [],
    diff = [];
  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }
  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }
  for (let k in a) {
    diff.push(k);
  }
  return diff;
}
</script>

<template>
  <div id="modal-card-foldereditcollaborators" class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add/edit folder collaborators</p>
    </header>
    <section class="modal-card-body">
      <!-- loading -->
      <b-loading
        :is-full-page="false"
        :active="isModalInitialDataLoaded == false"
        :can-cancel="true"
        animation=""></b-loading>

      <!-- access note -->
      <b-message
        type="is-info"
        v-show="
          isModalInitialDataLoaded == true &&
          userIsCollaborator == false &&
          orgIsCollaborator == false
        ">
        FYI - your current access here is view-only (role: viewer). Only collaborators here can
        add/edit folder access.
      </b-message>

      <!-- viewers -->
      <b-field label="Viewers" class="shareField" expanded>
        <div class="shareInfo">
          <b-icon
            class="shareInfoIcon"
            icon="info-circle"
            size="is-small"
            type="is-light"
            pack="fal"></b-icon>
          Can view and download folder content and subfolders
        </div>
      </b-field>
      <b-field class="shareField" expanded>
        <!-- <b-taginput ref="viewersshare" :autocomplete="false" class="shareTag" :disabled="userIsCollaborator == false && orgIsCollaborator == false" v-model="collaboratorsViewers" :placeholder="isModalInitialDataLoaded ? 'Add email' : 'Loading viewers...'"></b-taginput> -->
        <EmailInput
          ref="viewersshare"
          v-model="collaboratorsViewers"
          :email-store="collaboratorsViewers"
          :data-store="emailInputData"
          :disabled="userIsCollaborator == false && orgIsCollaborator == false"
          :placeholder="isModalInitialDataLoaded ? 'Add email' : 'Loading viewers...'"
          :results-limit="30"
          :recents-tags-limit="5"
          :show-self-in-recents="false">
        </EmailInput>
      </b-field>
      <b-field class="orgAccess">
        <b-checkbox
          v-model="orgIsViewer"
          :disabled="userIsCollaborator == false && orgIsCollaborator == false"
          size="is-small"
          >Also grant this access to all members of my organization</b-checkbox
        >
      </b-field>

      <!-- editors -->
      <b-field label="Editors" class="shareField" expanded>
        <div class="shareInfo">
          <b-icon
            class="shareInfoIcon"
            icon="info-circle"
            size="is-small"
            type="is-light"
            pack="fal"></b-icon>
          Can view, modify, share, and download folder content and subfolders
        </div>
      </b-field>
      <b-field class="shareField" expanded>
        <EmailInput
          ref="editorsshare"
          v-model="collaboratorsEditors"
          :email-store="collaboratorsEditors"
          :data-store="emailInputData"
          :disabled="userIsCollaborator == false && orgIsCollaborator == false"
          :placeholder="isModalInitialDataLoaded ? 'Add email' : 'Loading editors...'"
          :results-limit="30"
          :recents-tags-limit="5"
          :show-self-in-recents="false">
        </EmailInput>
      </b-field>
      <b-field class="orgAccess">
        <b-checkbox
          v-model="orgIsCollaborator"
          :disabled="userIsCollaborator == false && orgIsCollaborator == false"
          size="is-small"
          >Also grant this access to all members of my organization</b-checkbox
        >
      </b-field>

      <!-- Share -->
      <b-field label="Share" class="shareField" expanded> </b-field>
      <b-field expanded>
        <b-input expanded :value="folderShareURL" type="text" icon-pack="fal" icon="link"></b-input>
        <p class="control">
          <button
            class="button is-outline"
            title="Open in a new tab"
            v-on:click="openTab(folderShareURL, true)">
            <b-icon pack="fal" icon="external-link-alt"></b-icon>
          </button>
        </p>
        <p class="control">
          <button
            class="button is-outline"
            title="Copy the URL"
            v-on:click="copyToClipboard(folderShareURL)">
            <b-icon pack="fal" icon="copy"></b-icon>
          </button>
        </p>
        <p class="control">
          <button
            class="button is-outline"
            title="Compose message with URL in default email client"
            v-on:click="
              openMailTo(
                'Shared folder: ' + currentfoldername,
                'You are invited to collaborate on the folder %22' +
                  currentfoldername +
                  '%22:<BR>' +
                  folderShareURL,
                '',
              )
            ">
            <b-icon pack="fal" icon="envelope"></b-icon>
          </button>
        </p>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <HrbrButton :button="closeButton" @click="closeModal"/>
      <HrbrButton :button="updateButton" @click="updateFolderAccessTimeout"/>
    </footer>
  </div>
</template>

<style>
#modal-card-foldereditcollaborators .tag span {
  color: #4a4a4a;
}
#modal-card-foldereditcollaborators .taginput-container {
  min-height: 89px;
}
#modal-card-foldereditcollaborators .taginput-container .tag,
.taginput-container .control {
  align-self: flex-start;
}
#modal-card-foldereditcollaborators .mdi-tag:before {
  content: '\f02b';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-style: normal;
}
#modal-card-foldereditcollaborators .shareInfo {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 4px;
}
#modal-card-foldereditcollaborators .orgAccess {
  margin-bottom: 20px;
  margin-left: 2px;
}
#modal-card-foldereditcollaborators .shareInfoIcon i {
  color: #8c8c8c;
  margin-left: 3px;
}
#modal-card-foldereditcollaborators .shareField:not(:last-child) {
  margin-bottom: 4px;
}
#modal-card-foldereditcollaborators .shareField .label {
  margin-bottom: 2px;
}

#modal-card-foldereditcollaborators .is-primary i {
  color: white;
}
#modal-card-foldereditcollaborators .modalIcon {
  font-size: 12px;
}
#modal-card-foldereditcollaborators .modalInset {
  padding: 15px;
  background-color: #f3f3f3;
  border-radius: 5px;
}
#modal-card-foldereditcollaborators .modalInsetHolder {
  min-height: 382px;
}

#modal-card-foldereditcollaborators .b-checkbox.checkbox .control-label {
  color: #838383;
  font-size: 14px;
}

#modal-card-foldereditcollaborators .control .fal {
  color: #929292;
}
#modal-card-foldereditcollaborators .select:not(.is-multiple):not(.is-loading):after {
  border-color: #ccc;
}
#modal-card-foldereditcollaborators .modalHelperText {
  font-size: 13px;
  color: #0000008f;
  margin: 2px;
  margin-top: -10px;
}

#modal-card-foldereditcollaborators #currentSharingGroups {
  padding-top: 15px;
  border-top: 1px solid #cccc;
  margin-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

#modal-card-foldereditcollaborators .shareGroupIcon {
  color: #c3c3c3;
  padding-left: 3px;
  padding-right: 2px;
}

#modal-card-foldereditcollaborators .sharinggroupname {
  color: #666;
  font-weight: 600;
  font-size: 15px;
}
</style>
